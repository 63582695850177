import React, { useEffect, useState } from 'react'
import Modal from 'react-modal'
import { useLocation, useNavigate } from 'react-router-dom'

import { usePatientReceiptByDocumentIdQueryQuery } from '../../generated/hooks'
import { getInvoiceId, getMemberId } from '../../utils/invoiceUtils'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: 'none',
  },
}

interface InvoiceDataInterface {
  id: string
  document: string
}

interface InvoiceModalProps {
  isOpen?: boolean
}

const InvoiceModal: React.FC<InvoiceModalProps> = ({ isOpen = false }) => {
  // Setup for app navigation based on user actions.
  const navigate = useNavigate()
  const location = useLocation()

  // Initialization for invoice and modal state.
  let subtitle: HTMLHeadingElement | null
  const [modalIsOpen, setIsOpen] = React.useState(isOpen)
  const [invoice, setInvoice] = useState<InvoiceDataInterface | null>(null)

  // Functionality for when the modal is to be opened.
  function openModal() {
    setIsOpen(true)
  }

  // Functionality for after the modal is opened.
  function afterOpenModal() {
    if (subtitle) {
      subtitle.style.color = '#f00'
    }
  }

  // Functionality for when the modal is to be closed.
  function closeModal() {
    setIsOpen(false)
  }

  // Navigates to the payment route.
  function goToPay() {
    closeModal()
    navigate('/payment', { state: location.state })
  }

  // Query for getting the invoice/document data, requires contactId and documentId.
  const { data, loading, error } = usePatientReceiptByDocumentIdQueryQuery({
    variables: {
      contactId: getMemberId() || '',
      documentId: getInvoiceId() || '',
    },
  })

  // Sets the invoice state once we have data. Using useEffect to do it once.
  useEffect(() => {
    if (
      data &&
      data.patientReceiptByDocumentId.__typename === 'PatientReceiptByDocumentIdQueryResult' &&
      data.patientReceiptByDocumentId.document !== null
    ) {
      setInvoice({
        id: getInvoiceId() || '',
        document: data.patientReceiptByDocumentId.document || '',
      })
    }
  }, [data])

  // Show loading or error message if applicable.
  if (loading) {
    return <div>Loading...</div>
  }
  if (error) {
    return <div>Error! ${error.message}</div>
  }

  // Build the pdf document from the base64 string.
  const pdfDocument = (src: string) => {
    return 'data:application/pdf;base64,' + src
  }

  // The actual modal component.
  return (
    <div className="invoice-modal">
      <a onClick={openModal} className="view-invoice">
        View Invoice
      </a>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Invoice Modal"
        ariaHideApp={false}
      >
        {/* <div className="modal" tabIndex={-1}> */}
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal__header d-flex justify-content-between align-items-center">
              <h2 className="modal__title">View Invoice</h2>
              <button
                className="modal__button btn-close top-right"
                onClick={closeModal}
                type="button"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>

            <div className="modal-body">
              {invoice && <embed src={pdfDocument(invoice?.document)} type="application/pdf" />}
            </div>
            <div className="modal-footer">
              {invoice && (
                <>
                  <a
                    href={pdfDocument(invoice?.document)}
                    download="invoice.pdf"
                    rel="noreferrer"
                    className="pdf-download"
                  >
                    Download PDF
                  </a>
                  <button onClick={goToPay} className="btn btn-primary">
                    Pay Now
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default InvoiceModal
