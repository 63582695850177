import { ApolloProvider } from '@apollo/client'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import { useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Navigate } from 'react-router-dom'

import { useApolloClient } from '../hooks/useApolloClient'
import { push_pageview } from '../service/analytics'
import { generateRouteWithInvoice, isInvoiceFlow } from '../utils/invoiceUtils'
import PayForm from './Forms/PayForm'
import Balance from './misc/Balance'
import Navigation from './misc/Navigation'

export interface PaymentInfoPropState {
  mdvipId: string
  balance: string
  dob: string
  token: string
  estimatedPaidThrough: string
}

const PaymentInfo: React.FC = () => {
  useEffect(() => {
    push_pageview({ page_id: 'express_pay_payment_info', page_name: 'Add Payment Info' })
  }, [])

  window.sessionStorage.setItem('session', '1')
  const location = useLocation()
  const { balance, dob, token, mdvipId, estimatedPaidThrough } = location.state as PaymentInfoPropState
  const client = useApolloClient(token)

  // Invoice flow setup.
  const isInvoice = isInvoiceFlow()
  const verifyAccountRoute = generateRouteWithInvoice('/')

  return client ? (
    <ApolloProvider client={client}>
      <div className="page-payment-info form-content">
        {window.sessionStorage.getItem('session') === '0' && <Navigate replace to={verifyAccountRoute} />}
        <Navigation activeIndex={1} />

        <div className="main-content">
          <div className="back-link">
            <KeyboardBackspaceIcon />
            <Link className="log-in" to={verifyAccountRoute}>
              Verify Account
            </Link>
          </div>

          <h1>Add Payment Info</h1>
          <div className="content payment-info">
            <div className="member-info">
              <div className="table-row">
                <div className="table-column">
                  <div className="label">Member ID</div>
                  <div className="info" data-private>
                    {mdvipId}
                  </div>
                </div>
                <div className="table-column">
                  <div className="label">Date of Birth</div>
                  <div className="info" data-private>
                    {dob}
                  </div>
                </div>
              </div>
              {!isInvoice && <Balance balance={parseFloat(balance)} estimatedPaidThrough={estimatedPaidThrough} />}
            </div>
            <div className="disclamer">
              Scheduled payments will be processed and reflected in your account in 1-2 days.
            </div>
            <hr />
            <div className="total-and-payment">
              <PayForm />
            </div>
          </div>
        </div>
      </div>
    </ApolloProvider>
  ) : null
}

export default PaymentInfo
