import LogRocket from 'logrocket'
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { RECAPTCHA_CLIENT_KEY } from '../config/config'
import { authenticate, AuthenticateParams, AuthenticateResponse } from '../service/authenticate'
import { formatServerDateString } from '../utils/dateUtils'
import { generateRouteWithInvoice, removeInvoiceId } from '../utils/invoiceUtils'
import { useJwtExpired } from './useJwtExpired'

const AuthenticateContext = createContext<{
  token: string | null
  authenticatedMember: AuthenticateResponse['member'] | null
  errorMessage: string | null
  loading: boolean
  setParams: (params: AuthenticateParams | null) => void
}>({
  token: null,
  authenticatedMember: null,
  errorMessage: null,
  loading: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setParams: () => {},
})

export const AuthenticateProvider = ({ children }: { children: React.ReactNode }) => {
  const [token, setToken] = useState<string | null>(null)
  const [authenticatedMember, setAuthenticatedMember] = useState<AuthenticateResponse['member'] | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [params, setParams] = useState<AuthenticateParams | null>(null)
  const navigate = useNavigate()

  useEffect(() => {
    if (!params || (RECAPTCHA_CLIENT_KEY && !params.recaptchaToken)) {
      setToken(null)
      setLoading(false)
      return
    }

    setToken(null)
    setAuthenticatedMember(null)
    setLoading(true)

    authenticate({
      ...params,
      dob: formatServerDateString(params.dob),
    })
      .then((response) => {
        setToken(response.token)
        setAuthenticatedMember(response.member)
        setErrorMessage(null)
        setLoading(false)
      })
      .catch((error: any) => {
        setToken(null)
        setAuthenticatedMember(null)
        setErrorMessage(error.message)
        setLoading(false)

        if (params?.onAuthenticateError) {
          params.onAuthenticateError(error.message)
        }
      })
  }, [params])

  useEffect(() => {
    if (params && token && authenticatedMember) {
      LogRocket.identify(authenticatedMember.id)
      // Redirect to payment page.
      navigate('/payment', {
        state: {
          balance: authenticatedMember.balance,
          mdvipId: authenticatedMember.mdvipId,
          dob: params.dob,
          token,
          estimatedPaidThrough: authenticatedMember.estimatedPaidThrough,
          contact_id: authenticatedMember.id,
        },
      })
      return
    }
  }, [token, authenticatedMember])

  // Handle Token expiration
  const expiredRoute = generateRouteWithInvoice('/', 'expired=true')
  useJwtExpired(() => {
    setToken(null)
    setAuthenticatedMember(null)
    setLoading(false)
    setErrorMessage('Previous session was securely ended.')
    setParams(null)
    removeInvoiceId()
    navigate(expiredRoute)
  }, token)

  const value = useMemo(
    () => ({
      token,
      authenticatedMember,
      errorMessage,
      loading,
      setParams,
    }),
    [token, authenticatedMember, loading, errorMessage],
  )

  return <AuthenticateContext.Provider value={value}>{children}</AuthenticateContext.Provider>
}

export default function useAuthenticateContext() {
  return useContext(AuthenticateContext)
}
